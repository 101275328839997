import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"


const HorizontalButton = ({ text, link }) => {

  let button_url
  let pattern = /^\/{1}[^/]/i

  if (pattern.test(link)) {
    button_url = <Link to={link} className="text-white">{text}</Link>
  } else {
    button_url = <a href={link} rel="noopener noreferrer" target="_blank" className="text-white">{text}</a>
  }

  return (
    <div className="horizontal-button flex-grow-1 p-2 text-center">
      <div className="button d-inline-block px-4 py-2" style={{width: '260px', backgroundColor: '#fc5c00'}}>
        { button_url }
      </div>
    </div>
  )
}

HorizontalButton.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
}

HorizontalButton.defaultProps = {
  text: ``,
  link: ``,
}

export default HorizontalButton
