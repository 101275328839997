import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { MDXRenderer } from "gatsby-plugin-mdx"
import ReviewScriptText from '../components/reviewscripttext/reviewscripttext'
// import VimeoPlayer from '../components/vimeoplayer/vimeoplayer'
import Callus from '../components/callus/callus'
import HorizontalButtons from "../components/horizontalbuttons/horizontalbuttons"
import HorizontalButton from "../components/horizontalbutton/horizontalbutton"
// import ButtonBlock from "../components/buttonblock/buttonblock"
// import ButtonBlocks from "../components/buttonblocks/buttonblocks"


export default function reviewScriptPages({ data: { mdx } }) {
  return (
    <Layout>

      <SEO title={mdx.frontmatter.title} />

      <ReviewScriptText>
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </ReviewScriptText>

      <Callus />

      <HorizontalButtons>
        <HorizontalButton
          text="Download Printable Script"
          link={mdx.frontmatter.script}
        />
        <HorizontalButton
          text="Quick Q&A Session"
          link="//bestlocalreviewstraining.as.me/?appointmentType=12872884"
        />
        <HorizontalButton
          text="Live Demo"
          link="//bestlocalreviewstraining.as.me/?appointmentType=3761722"
        />
      </HorizontalButtons>

    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        url
        title
        script
      }
    }
  }
  `