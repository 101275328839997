import React from "react"
import './reviewscripttext.scss'

const ReviewScriptText = ({ children }) => (
  <div className='review-scripts'>
    {children}
  </div>
)

export default ReviewScriptText
