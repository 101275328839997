import React from "react"
import PropTypes from "prop-types"
//import './horizontalbuttons.scss'


const HorizontalButtons = ({ children }) => {
  return (
    <div className="horizontal-buttons d-flex flex-wrap justify-content-between w-100 mt-2" style={{fontFamily: 'Montserrat'}}>
      {children}
    </div>
  )
}

HorizontalButtons.propTypes = {
  children: PropTypes.node.isRequired,
}

export default HorizontalButtons
